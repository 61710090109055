import React, {Props} from "react";
import style from "./login.module.css";
import Logo from "./media/1.png";
import ReactDOM from "react-dom";


class Login extends React.Component<Props<any>,Object> {



    accountChange=(e: React.ChangeEvent<HTMLInputElement>) =>{
        this.setState((s) => ({...s, account: e.target.value}));
    }

    passwordChange=(e: { target: { value: string } }) =>{
        this.setState((s) => ({...s, password: e.target.value}));
    }

    onClick=() =>{
        if(this.state.loading) return;
        this.setState((s) => ({...s, loading: true}))
        console.log(this.state.account,this.state.password)

    }

    state = {
        loading: false,
        account: "",
        password: ""
    }

    render() {
        return (
            <>

                <div className={style.logo}>
                    <div className={style.shadow}>
                        <img src={Logo} alt="metaplay"/>
                    </div>
                    <br/>
                    <p>元乐游</p>
                </div>

                <div className={style.log}>
                    <div className={style.logItem}>
                        <p>账号</p>
                        <input onChange={this.accountChange} type="text" placeholder="请输入您的账号"/>
                    </div>
                    <div className={style.logItem}>
                        <p>密码</p>
                        <input onChange={this.passwordChange} type="password" placeholder="请输入您的密码"/>
                    </div>
                    <Button loading={this.state.loading} onClick={this.onClick}/>
                    <div className={style.info}>
                        <span>忘记密码</span>
                        <span>注册账号</span>
                    </div>
                </div>

            </>
        )
    }
}

type ButtonProps = {
    loading: boolean,
    onClick: (e:React.MouseEvent<HTMLButtonElement,MouseEvent>) => void
}

function Button({loading, onClick}: ButtonProps) {
    return (
        <button
            type="button"
            className={style.logButton}
            onClick={onClick}
        >
            {loading?"登录中":"登录"}
        </button>
    )
}


export default Login;
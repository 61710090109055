import React from 'react';
import './App.css';
import Login from "./login"
import {BrowserRouter,Route,Routes} from "react-router-dom";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login/>}/>
            <Route path="login" element={<Login/>}/>
            <Route path="*" element={<h1>404 Not Found</h1>} />
         </Routes>
      </BrowserRouter>
  );
}

export default App;
